// Header.js
import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './header.css'; // Make sure to create a corresponding CSS file
import { FaAvianex, FaFileInvoiceDollar, FaHome, FaInbox, FaSchool } from 'react-icons/fa';
import logo from '../../assets/logo1.png';
import { logout_ApiManager } from '../ApiManager';
import swal from 'sweetalert';
import { removeUserSession } from '../utilies/Common';

const Header = () => {
    const user = JSON.parse(localStorage.getItem('agent'));
    const navigate = useNavigate();

    useEffect(() => {
      console.log(user)
    }, []);
  
     const handleLogout = () => {
      swal({
        title: "Logging out",
        text: "Are you sure to log out?",icon: "warning", buttons: true, dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
            removeUserSession();
            // logout user when password changed
                logout_ApiManager((res) => {
                console.log('logging out',res)
                swal({title: "Success!",text: 'Logged out!!', type: "success", timer: 1000})
                localStorage.removeItem('token');
                navigate('/login');
             })
        }   
    });
    };
 
     //Search Api
    const onSearchChange = (keyword) => {
      
    }
  
  return (
    user && user.agentname ?
    <header className="header row align-items-stretch px-4">
    <div className='row px-2'>
    <div className='col-lg-4'>
      <div id="logo" className="sidebar-brand-text"> 
          <img src={logo} alt="Logo" width="130"/>
      </div>
    </div>
    <div className='col-lg-5'>
    <input type="text" className="form-control search bg-white " placeholder="Search" 
        onChange={(e) => onSearchChange(e.target.value)} />
    </div>
    <div className='col-lg-3'>
    <div className='d-flex float-right w-100 justify-content-right'>
    <a class="icon-button svg">
    <NavLink to="/my-profile" className=" " activeClassName="active-tab">
    <img class="profile-icon" height="35" src="https://api.aptitudemigration.com.au/public/profile_images/user.svg" />
    </NavLink>
    </a>
    <h6 className="mb-0 mt-2 acc-label size-14 bold">{user['name']} </h6>
    </div>
    <div className='text-right acc-label size-14 btn btn-dark float-right py-1' 
     onClick={handleLogout}>Logout</div>
    </div>
    <div className='row mt-3'>
    <div className="menu size-12 white custom-font4 w-100 mb-2">
      <NavLink to="/dashboard" className="no-underline menu-item " activeClassName="active-tab">
        <FaHome size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Dashboard</span>
      </NavLink>
      <NavLink to="/student-list" className="no-underline menu-item " activeClassName="active-tab">
        <FaSchool size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Students</span>
      </NavLink>
      <NavLink to="/education-apps" className="no-underline menu-item " activeClassName="active-tab">
        <FaSchool size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Education Application</span>
      </NavLink>
      <NavLink to="/migration-apps" className="no-underline menu-item" activeClassName="active-tab">
        <FaAvianex size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Migration Application</span>
      </NavLink>
      <NavLink to="/inbox" className="no-underline menu-item" activeClassName="active-tab">
        <FaInbox size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Inbox</span>
      </NavLink>
      <NavLink to="/payment" className="no-underline menu-item" activeClassName="active-tab">
        <FaFileInvoiceDollar size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Payment</span>
      </NavLink>
    </div>
      </div>
    </div>

    </header>:''
  );
};

export default Header;
