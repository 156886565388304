// App.js
import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './components/Login/login';
import Dashboard from './components/Dashboard/dashboard';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import Logout from './components/Logout';
import { io } from 'socket.io-client';
import swal from 'sweetalert';
import { get_ApiManager } from './components/ApiManager';
import Header from './components/Header/header';
import MigrationApps from './components/Applications/migApplications';
import { isTokenValid } from './components/utilies/auth'
import Profile from './components/Profile/profile';
import StudentProfile from './components/Student/studentProfile';
import StudentList from './components/Student/studentList';

function App() {
  const [socket, setSocket] = useState(null);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const shouldHideSidebar = location.pathname === '/login' || location.pathname === '/';

  useEffect(() => {
    const checkToken = async () => {
      const valid = await isTokenValid();
      if (!valid) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setIsLoggedIn(true);
      }
    };

    checkToken();
  }, [navigate]);

  return (
    <div className="app-container">
      {!shouldHideSidebar && (
        <>
          <Header />
        </>
      )}
      <div className="">
        <Routes>
          <Route path="/login" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<PrivateRoute isLoggedIn={isLoggedIn}> <Dashboard /></PrivateRoute>} />
          <Route path="/my-profile" element={<PrivateRoute isLoggedIn={isLoggedIn}> <Profile /></PrivateRoute>} />
          <Route path="/student-profile/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}> <StudentProfile /></PrivateRoute>} />
          <Route path="/student-list" element={<PrivateRoute isLoggedIn={isLoggedIn}> <StudentList /></PrivateRoute>} />
          <Route path="/migration-apps" element={<PrivateRoute isLoggedIn={isLoggedIn}> <MigrationApps /></PrivateRoute>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
