
import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import { Link } from 'react-router-dom';
import { view_ApiManager } from '../ApiManager';
import moment from 'moment';
import Paginations from '../GenericComponents/Pagination';

class StudentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage:1,
            lastPage:'',
            perPage: 10,
            totalItem:'',
            allStudents:[],
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: false},
                {title: 'Name', dataKey: "counselor", align:"text-center", key: 2, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email", align: "text-center ",  key: 3, checked: true, canChange: false},
                {title: 'Phone' , dataKey: "mobile", align: "text-center ",  key: 4, checked: true, canChange: true},
                {title: 'Status', dataKey: "status", align:"text-center", key: 5, checked: true, canChange: true },
                {title: 'Edu App', dataKey: "eduCount", align:"text-center", key: 6, checked: true, canChange: true },
                {title: 'Created_At' , dataKey: "created_at", align: "text-center ",  key: 7, checked: true, canChange: false},
                {title: '' , dataKey: "", align: "text-center ",  key: 8, checked: true, canChange: false},
            ],
        }
    }
     componentDidMount() {
        this.getStudents();
    }

     getStudents(){
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `getAllStudents`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('all-students',res);
            this.setState({
                allStudents: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
          
        },(err) => {
            console.log(err);
        });
    }

    render(){
        return(
            <div className='row px-4 pb-4 pt-2'>
           <div className='d-flex my-3'>
                <Link to={'/student-profile'}><button className='text-left btn btn-main mx-2'>Create Student</button></Link>
            </div>

        <div className='mt-2'>
        <table className={`table table-bordered bg-white mb-1`}>
        <thead className="thead-dark">
         <tr>
         {this.state.headers.map(head => ( head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>
         <tbody>
         {this.state.allStudents.map((value, index) => (
            <tr key={value.id}>
            <td className='text-center'>{++index}</td>
            <td className='text-center'>{value.name}</td>
            <td className='text-center'>{value.email}</td>
            <td className='text-center'>{value.mobile}</td>
            <td className='text-center'>{value.assignedTo}</td>
            <td className='text-center'>{value.eduCount}</td>
            <td className='text-center'>{moment(value.created_at).format('D MMMM YYYY')}</td>
            <td className='text-center'><Link to={`/student-profile/${value.id}`}><button className='text-left btn btn-main'>Student Profile</button></Link></td>
            </tr>
         ))}
        </tbody>
         </table>
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handlePaginationCallback} 
        />

        </div>
        )
    }
}
export default StudentList;