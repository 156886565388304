
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Profile = () => {

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('agent'));
        if (user) {
          getDetail();
        }
    }, []);

    const getDetail = () => {
        const user = JSON.parse(localStorage.getItem('agent'));
        // let url = `getDashboardDetails/${user['id']}`;
        // console.log(url);
        // get_ApiManager('', url, (res) => {
        //   console.log('dashboard', res);
        //   setEduCount(res.data.data['edu']);
        //   setMigCount(res.data.data['mig']);
        //   setInboxCount(res.data.data['inbox']);
        // }, (err) => {
        //   console.log(err);
        // });
      };

    return (
        <div className="row px-4 pb-4 pt-2">
        profile
        </div>
    )
}
export default Profile;