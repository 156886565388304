import React ,{useState, useEffect}from "react"
import { view_ApiManager } from "../ApiManager";
import Loader from "../GenericComponents/Loader";
import { Sorting } from "../GenericComponents/SortTables";
import Paginations from "../GenericComponents/Pagination";
import Moment from "react-moment";
import { Link } from "react-router-dom";


const MigrationApps = () => {
    const user = useState(JSON.parse(localStorage.getItem('agent')));
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [migIndex, setMigIndex] = useState('');
    const [perItem, setPerItem] = useState('');
    const [allMigApps, setAllMigApps] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [headers] = useState([ 
        {title: '#' , dataKey: "index", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Institute', dataKey: 'providername', align:"text-center", key:2, checked: true, canChange: false},
        {title: 'Application Type', dataKey: 'coursename', align:"text-center", key:3, checked: true, canChange: false},
        {title: 'Date Lodged', dataKey: 'datelodged', align:"text-center", key:4, checked: true, canChange: true},
        {title: 'Status' , dataKey: "status", align:"text-center", key:5, checked: true, canChange: true},
        {title: 'Case Manager' , dataKey: "assignedTo", align:"text-center", key:6, checked: true, canChange: true},
    ]);
   
    useEffect( () => {
        getMigApplications();
    }, [currentPage, perPage]);

     // all migration app list
    const getMigApplications = () => {
        setLoading(true);
        let url = `p-migrationApps/${user[0]['id']}`;
        console.log(url, currentPage, perPage);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('mig-applications',res);
            setLoading(false);
            setAllMigApps(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setMigIndex(currentPage * perPage - perPage + 1);
        },(err) => {
            console.log(err);
        });
    }
       
    const sorting = (col) => {
        const sortResult = Sorting(allMigApps, col, order)
        setOrder(sortResult[0]);
        setAllMigApps(sortResult[1]);
    }
     // Pagination callBack function
     const handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if(perPage && perPage !== ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
    }

    return(
        <>
        <div className="row px-3">
        {loading ? <Loader /> :
        <>
        <div className="table-responsive">
        <table id="allmig" className="table table-bordered bg-white">
         {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {headers.map(head => (
         head.checked &&
        <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
        {head.title}</th>
        ))}
        </tr>
        </thead>
        {/* End Headers */}
        <tbody>
        {allMigApps.map((value, index) =>
        <tr key={index++}>
        <td className="text-center">{migIndex+index}</td>
        <td className="text-left"><Link to={`/migration-application/${value.appid}`}>{value.coursename}</Link></td>
        <td className="text-center">{value.providername}</td>
        <td className="text-center"><small>
        <Moment date={value.datelodged} format="D MMM YYYY"></Moment>
        </small></td>
        <td className="text-center">{value.status}</td>
        <td className="text-center">{value.assignedTo}</td>
        </tr>)}
        </tbody>
        </table>
        </div>
        <Paginations
         currentPage={currentPage}
         total= {totalItem}
         itemsPerPage= {perPage}
         lastPage={lastPage}
         totalItems={totalItem}
         perItem={perItem}
         onPageChange= {handlePaginationCallback} 
        />
        </>}
        </div>
        </>
    )

}
export default MigrationApps