import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css';
import { get_ApiManager } from '../ApiManager';
import { FaAvianex, FaFileInvoiceDollar, FaRocketchat, FaSchool, FaUserEdit } from 'react-icons/fa';
// import { AnotherTitle as Title } from '../GenericComponents/Title';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Define the plugin to display total in the center of the doughnut chart
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();

    // Get the center point of the canvas
    const centerX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
    const centerY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
    
    // Set text properties
    ctx.font = '26px Montserrat sans-serif'; // Adjust font size and family
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black'; // Adjust text color if needed

    // Draw text
    const total = chart.config._total || ''; // Assuming total is set on the config
    ctx.fillText(total, centerX, centerY);

    ctx.restore();
  }
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const Dashboard = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [eduCount, setEduCount] = useState('');
  const [migCount, setMigCount] = useState('');
  const [inboxCount, setInboxCount] = useState('');
  const data = [
    {
      intake: "September/October 2024",
      appSubmitted: 7,
      appProcessed: 0,
      appNotProcessed: 1,
      caseClosed: 1,
      totalOffers: 5,
      payment: 0
    }
  ];
  const totals = data.reduce((acc, row) => ({
    appSubmitted: acc.appSubmitted + row.appSubmitted,
    appProcessed: acc.appProcessed + row.appProcessed,
    appNotProcessed: acc.appNotProcessed + row.appNotProcessed,
    caseClosed: acc.caseClosed + row.caseClosed,
    totalOffers: acc.totalOffers + row.totalOffers,
    payment: acc.payment + row.payment
  }), {
    appSubmitted: 0,
    appProcessed: 0,
    appNotProcessed: 0,
    caseClosed: 0,
    totalOffers: 0,
    payment: 0
  });

  const dataIntake = {
    labels: [
      'University of East London',
      'Leeds Trinity University',
      'Ravensbourne University London',
      'Glasgow Caledonian University',
      'Oxford International Education Group',
      'University of Stirling',
      'Ulster University - London Campus'
    ],
    datasets: [
      {
        label: 'Intake',
        data: [8, 7, 6, 5, 4, 3, 2], // Example data
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56']
      }
    ]
  };
  const options = {
    indexAxis: 'y', 
    borderWidth: 1,// Horizontal bar chart
    elements: {
      bar: {
        borderWidth: 1,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Intake by University'
      },
      layout: {
            padding: {
                left: 0,
                top: 0
            }
          },
     
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('agent'));
    if (user) {
      getDetail();
    }
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext("2d");
  
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
  
      const data = [2, 2, 1, 3]; // Your actual data
      const total = data.reduce((acc, curr) => acc + curr, 0); // Calculate total dynamically
  
      chartInstance.current = new Chart(myChartRef, {
        type: 'doughnut',
        data: {
          labels: ['Application Received', 'Case Closed', 'Application Not Processed', 'Offer Issued'],
          datasets: [{
            data: data,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 20,
                font: {
                  size: 14
                }
              }
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          }
        },
        plugins: [centerTextPlugin]
      });
  
      // Pass the total to the plugin through the chart config
      chartInstance.current.config._total = total;
  
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, []);
  
  

  const getDetail = () => {
    const user = JSON.parse(localStorage.getItem('agent'));
    let url = `getDashboardDetails/${user['id']}`;
    console.log(url);
    get_ApiManager('', url, (res) => {
      console.log('dashboard', res);
      setEduCount(res.data.data['edu']);
      setMigCount(res.data.data['mig']);
      setInboxCount(res.data.data['inbox']);
    }, (err) => {
      console.log(err);
    });
  };

  return (
    <div className="row px-4 pb-4 pt-2">
      {/* <Title title={'Dashboard'}/> */}
      <div className='row mb-4'>
      <div className="col-md-6 col-sm-12 col-lg-6 px-2 col-xl-3 col-xs-12 padding align-items-stretch">
        <Link className="no-underline" to={'/profile'}>
          <div className='card tile'>
            <div className="tile-heading">Application Submitted Today</div>
            <div className="tile-body">
              <i className="fa-solid fa-user"></i>
              <h2 className="float-end"><span id="app_rec_today"></span>1</h2>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-md-6 col-sm-12 px-2 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
        <Link className="no-underline" to={'/profile'}>
          <div className='card tile'>
            <div className="tile-heading">Webinar</div>
            <div className="tile-body">
              <div className="text-start text-center text-danger">Webinar Not Available</div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-md-6 col-sm-12 px-2 col-lg-6 col-xl-6 px-2 col-xs-12 padding align-items-stretch">
        <Link className="no-underline" to={'/profile'}>
          <div className='card tile'>
            <div className="card" style={{ paddingRight: "2rem" }} id="myChartParentContainer">
              <canvas className="customCanvas" id="myChart" ref={chartRef} style={{ padding: '10px' }}></canvas>
            </div>
          </div>
        </Link>
      </div>
      </div>

      <div className='row px-2'>
      <div className="table-responsive">
      <table className="table table-bordered table-hover table-striped">
        <thead className="thead-light">
          <tr>
            <th>Intake</th>
            <th>No. of App. Submit</th>
            <th>No of App. Process</th>
            <th>App Not Process</th>
            <th>Case Close</th>
            <th>Total Offer</th>
            <th>Payment</th>
          </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
            <tr key={index}>
              <td className='text-center'>{row.intake}</td>
              <td className='text-center'>{row.appSubmitted}</td>
              <td className='text-center'>{row.appProcessed}</td>
              <td className='text-center'>{row.appNotProcessed}</td>
              <td className='text-center'>{row.caseClosed}</td>
              <td className='text-center'>{row.totalOffers}</td>
              <td className='text-center'>{row.payment}</td>
            </tr>
          ))}
          <tr className="">
          <td className='text-right bold'>Total</td>
            <td className='text-center bold'>{totals.appSubmitted}</td>
            <td className='text-center bold'>{totals.appProcessed}</td>
            <td className='text-center bold'>{totals.appNotProcessed}</td>
            <td className='text-center bold'>{totals.caseClosed}</td>
            <td className='text-center bold'>{totals.totalOffers}</td>
            <td className='text-center bold'>{totals.payment}</td>
          </tr>
        </tbody>
        </table>
        </div>

      </div>

      <div className='row'>
      <div>
      <Bar data={dataIntake} options={options} />
    </div>
      </div>

    </div>
  );
}

export default Dashboard;
